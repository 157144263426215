import "./App.css";
import ReactGA from "react-ga4";

const title = "CreativeConnect";
const subtitle =
  "Supporting Reference Recombination for Graphic Design Ideation with Generative AI";

const authors = [
  {
    name: "DaEun Choi",
    url: "https://daeunchoi.com/",
    img: "/img/daeun.jpg",
    affiliation: "KAIST",
  },
  {
    name: "Sumin Hong",
    img: "/img/sumin.jpg",
    affiliation: "Seoultech",
  },
  {
    name: "Jeongeon Park",
    url: "https://jeongeonpark.com/",
    img: "/img/jeongeon.jpg",
    affiliation: "DGIST",
  },
  {
    name: "John Joon Young Chung",
    url: "https://johnr0.github.io/",
    img: "/img/john.jpg",
    affiliation: "Midjourney",
  },
  {
    name: "Juho Kim",
    url: "https://juhokim.com/",
    img: "/img/juho.jpg",
    affiliation: "KAIST",
  },
];

const buttons = [
  {
    name: "Paper",
    url: "https://dl.acm.org/doi/10.1145/3613904.3642794",
    icon: "/img/dl_logo.png",
  },
  {
    name: "Arxiv",
    url: "https://arxiv.org/abs/2312.11949",
    icon: "/img/arxiv-logomark-small.svg",
  },
  {
    name: "Code",
    url: "https://github.com/kixlab/creativeconnect",
    icon: "/img/code-icon.svg",
  },
];

const TRACKING_ID = "G-GMRVMYYRY1";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

function App() {
  return (
    <div className="container app">
      <div id="title">
        <h1 className="system-name" style={{ fontWeight: "800" }}>
          {title}
        </h1>
        <h3>{subtitle}</h3>
      </div>
      <div id="authors-container" className="row justify-content-center">
        {authors.map((author) => (
          <div class="author-container col-4 col-md-2">
            <div class="author-image">
              <a href={author.url}>
                <img src={author.img} alt={author.name} />
              </a>
            </div>
            <div className="mt-3">
              <a href={author.url}>{author.name}</a>
              <p className="author-affiliation">{author.affiliation}</p>
            </div>
          </div>
        ))}
      </div>
      <div id="buttons-container">
        {buttons.map((button) => (
          <a
            href={button.url}
            className={`button ${button.url === "" ? "disabled" : ""}`}
            target="_blank"
            rel="noreferrer"
          >
            {button.name}
            {button.icon && (
              <img className="m-auto" src={button.icon} alt={button.name} height={16} />
            )}
          </a>
        ))}
      </div>

      <hr />
      {/* <h2>30-second Video Teaser</h2> */}
      <div style={{ maxWidth: "700px", margin: "20px auto" }}>
        <div className="ratio ratio-16x9">
          <iframe
            src="https://www.youtube.com/embed/YDShK6LcwGI?si=ArWBtomKVtr_mYqs"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <p className="text-start">
        Designers often get a new idea by recombining the existing design ideas.{" "}
        <span className="title-highlight system-name">CreativeConnect</span> is a generative AI
        system that helps graphic designers' early-stage ideation process by supporting reference
        recombination. It helps users discover useful elements from the reference image using
        keywords, recommends relevant keywords, generates diverse recombination options with
        user-selected keywords, and shows recombinations as sketches with text descriptions.
      </p>
      <img src="/img/teaser_figure.png" alt="teaser" className="img-fluid" />
      <hr />

      <h2>System Interface</h2>
      <p className="text-start">
        <span className="title-highlight system-name">CreativeConnect</span> consists of a mood
        board where users can import reference images, and three panels that support reference
        recombination.
      </p>
      <img src="/img/system_annotated.png" alt="system_interface" className="img-fluid mb-4" />
      <p className="text-start">
        <span className="highlight feature1">Keyword Extraction Panel</span> helps users discover
        useful elements from the reference image. When users import a reference image into the mood
        board, the system extracts keywords based on four categories:{" "}
        <b>(1) Subject matter, (2) Action & pose, (3) Theme & Mood, and (4) Arrangement</b>.
      </p>
      <p className="text-start">
        <span className="highlight feature2">Keyword Suggestion Panel</span> recommends relevant
        keywords based on the extracted keywords. The system recommends similar keywords based on
        both (1) all keywords on the mood board and (2) the selected keywords.
      </p>
      <p className="text-start">
        <span className="highlight feature3">Recombination Panel</span> generates diverse
        recombination options with user-selected keywords, and shows recombinations as sketches with
        text descriptions. When the user selects keywords and clicks the merge button, the system
        generates three recombination options. The recombined results are first shown as one-line
        text descriptions, and the sketch is generated based on them. Users can also click the "More
        sketches" button to get more sketches with the same text description.
      </p>
      <hr />

      {/* <h2>Technical Pipeline</h2>
      <img src="/img/technical_pipeline.png" alt="pipeline" className="img-fluid" />
      <hr /> */}

      <h2>Video Figure</h2>
      <div className="ratio ratio-16x9">
        <iframe
          src="https://www.youtube.com/embed/EwItpky_noo?si=ozguaMRqVt5TPJhO"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
      <hr />

      <h2>Abstract</h2>
      <p className="text-start">
        Graphic designers often get inspiration through the recombination of references. Our
        formative study (N=6) reveals that graphic designers focus on conceptual keywords during
        this process, and want support for discovering the keywords, expanding them, and exploring
        diverse recombination options of them, while still having room for their creativity. We
        propose <span className="title-highlight system-name">CreativeConnect</span>, a system with
        generative AI pipelines that helps users discover useful elements from the reference image
        using keywords, recommends relevant keywords, generates diverse recombination options with
        user-selected keywords, and shows recombinations as sketches with text descriptions. Our
        user study (N=16) showed that{" "}
        <span className="title-highlight system-name">CreativeConnect</span> helped users discover
        keywords from the reference and generate multiple ideas based on them, ultimately helping
        users produce more design ideas and higher self-reported creativity, compared to the
        baseline system without generative pipelines. While{" "}
        <span className="title-highlight system-name">CreativeConnect</span> was effective in
        ideation, we discussed how{" "}
        <span className="title-highlight system-name">CreativeConnect</span> can be extended to
        support other types of tasks in creativity support.
      </p>
      <hr />

      <h2>Bibtex</h2>
      <pre className="text-start">
        {`@inproceedings{10.1145/3613904.3642794,
author = {Choi, DaEun and Hong, Sumin and Park, Jeongeon and Chung, John Joon Young and Kim, Juho},
title = {CreativeConnect: Supporting Reference Recombination for Graphic Design Ideation with Generative AI},
year = {2024},
isbn = {9798400703300},
publisher = {Association for Computing Machinery},
address = {New York, NY, USA},
url = {https://doi.org/10.1145/3613904.3642794},
doi = {10.1145/3613904.3642794},
booktitle = {Proceedings of the CHI Conference on Human Factors in Computing Systems},
articleno = {1055},
numpages = {25},
location = {Honolulu, HI, USA},
series = {CHI '24}
}`}
      </pre>
    </div>
  );
}

export default App;
